<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <div class="card-body">
                        <div class="float-sm-left">
                            <router-link
                                    to="/doctors"
                                    class="btn btn-info"
                            ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                            </router-link >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 mx-0">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar-xl mx-auto mt-1">
                            <div class="avatar-title bg-light rounded-circle">
                                <i class="mdi mdi-account h1 m-0 text-body"></i>
                            </div>
                        </div>

                        <h4 class="mt-3 mb-0">{{doctorDetail.surname}} {{doctorDetail.othernames}}</h4>
                        <p class="text-muted">{{doctorDetail.email}}</p>

                        <button
                                type="button"
                                class="btn btn-danger btn-xs waves-effect mb-2 waves-light"
                        >
                            {{doctorDetail.accountType}}
                        </button>

                        <div class="text-left mt-3">
                            <h4 class="font-13 text-uppercase">About Me :</h4>
                            <p class="text-muted font-13 mb-3">
                                {{doctorDetail.address}}
                            </p>

                            <div class="table-responsive">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Gender :</th>
                                        <td class="text-muted">{{ doctorDetail.gender}} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mobile :</th>
                                        <td class="text-muted">{{ doctorDetail.phoneNumber}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Date of birth :</th>
                                        <td class="text-muted"> {{ doctorDetail.dob | moment("MMMM Do YYYY")}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Place of Work :</th>
                                        <td class="text-muted">{{ doctorDetail.placeOfWork}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </div>
            </div>
            <!-- end col-->

            <div class="col-lg-8 col-xl-8 mx-0">
                <div class="card">
                    <div>
                        <lottie-loader :loading="showLoader" />
                    </div>
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Prescriptions
                                </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Patient's Name</th>
                                                <th>Phone Number</th>
                                                <th>Prescription Type</th>
                                                <th>Status</th>
                                                <th>Is Approved</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="p in prescriptions" :key="p.id">
                                                <td>{{p.id}}</td>
                                                <td v-if="p.patient.identity"> <span class="font-weight-bold font-12">{{p.patient.identity.surname}} {{p.patient.identity.othernames}}</span> </td>
                                                <td v-if="p.patient.identity"> <span class="badge badge-outline-primary">{{p.patient.identity.phoneNumber}}</span></td>
                                                <td> <span class="badge badge-outline-danger">{{p.prescriptionType}} </span> </td>
                                                <td>
                                                    <span class="badge  p-1"
                                                          :class="p.status === 'PENDING' ? 'badge-danger' : 'badge-primary' "
                                                    >{{p.status}}</span
                                                    >
                                                </td>
                                                <td>
                                                    <span class=" badge p-1"
                                                     :class="p.isApproved ? 'badge-primary' : 'badge-danger'"> {{p.isApproved ? "YES" : "NO"}}</span>
                                                </td>
                                                <td>
                                                   <router-link :to="'/perscription/1'">
                                                       <span class="text-primary">View</span>
                                                   </router-link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    <i class="mdi mdi-settings-outline mr-1"></i>Patients
                                </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Patient's Name</th>
                                                <th>Date of birth</th>
                                                <th>Gender</th>
                                                <th>Phone Number</th>
<!--                                                <th>Action</th>-->
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="p in patients" :key="p.id">
                                                <td>{{p.id}}</td>
                                                <td v-if="p.identity">{{ p.identity.surname}} {{p.identity.othernames}}</td>
                                                <td v-if="p.identity"> <span class="font-weight-bold font-12">{{  p.identity.dob == null ? "" :  p.identity.dob | moment("MMMM Do YYYY") }} </span></td>
                                                <td v-if="p.identity"><span class="badge badge-outline-info">{{p.identity.gender}}</span></td>
                                                <td v-if="p.identity">
                                                   <span class="font-12 font-weight-semibold badge badge-outline-primary">{{p.identity.phoneNumber}}</span>
                                                </td>
<!--                                                <td>-->

<!--                                                </td>-->
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
            </div>
            <!-- end col -->
        </div>

    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import LottieLoader from "../../../components/LottieLoader";

    export default {
        name: "ViewDoctor",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                showLoader : false,
                doctorDetail :{},
                prescriptions :[],
                patients : [],
                title: "Doctor Detail",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "doctor",
                        active: true,
                    },
                ],
            }
        },
        methods : {
              getDoctorInfoById() {
                   this.showLoader = true
                  BaseUrl.AxiosBearer.get(`/admin/doctors/profile/${this.$route.params.Id}`).then((resp) => {

                      if(resp.data.status) {
                          this.doctorDetail = resp.data.payload.identity
                           console.log(" Doctor Detail : ", this.doctorDetail)
                          this.getDoctorsPrescriptions()
                          this.getDoctorsPatients()
                          this.showLoader = false
                      }
                  }).catch((error) => {
                      console.log("error : ", error.message)
                  })
              },
            getDoctorsPrescriptions() {

                BaseUrl.AxiosBearer.get(`/admin/doctors/profile/${this.$route.params.Id}/prescriptions?page=1&pageSize=100000`).then((resp) => {

                    if(resp.data.status) {
                        this.prescriptions = []
                        this.prescriptions = resp.data.payload
                        //console.log("prescriptions : ", this.prescriptions )
                    }
                }).catch((error) => {
                    console.log("error : ", error.message)
                })
            },
            getDoctorsPatients() {

                BaseUrl.AxiosBearer.get(`/admin/doctors/profile/${this.$route.params.Id}/patients?page=1&pageSize=100000`).then((resp) => {
                    if(resp.data.status) {
                        this.patients = []
                        this.patients = resp.data.payload.patients
                        //console.log("patients : ", this.patients)
                    }
                }).catch((error) => {
                    console.log("error : ", error.message)
                })
            }
        },
        created() {
          this.getDoctorInfoById()
        }
    }
</script>

<style scoped>

</style>